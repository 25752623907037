<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="封面" min-width="110" align="center">
				  <template slot-scope="scope">
					<img :src="scope.row.images[0].url" style="width: 50px;height: 50px;" @click="viewImage(scope.row,'url')" v-if="scope.row.images && scope.row.images.length > 0">
					<img src="@/assets/images/empty.png" style="width: 50px;height: 50px;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="180" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.p_cate_name }}
				  </template>
				</el-table-column>
				<el-table-column label="分类" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.cate_name }}
				  </template>
				</el-table-column>
				<el-table-column label="价格" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.price }}
				  </template>
				</el-table-column>
				<el-table-column label="市场价" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.mktprice }}
				  </template>
				</el-table-column>
				<el-table-column label="库存" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.stock }}
				  </template>
				</el-table-column>
				<el-table-column label="排序" min-width="110" align="center">
				  <template slot-scope="{row, $index}">
					<div @click.stop="{{changeState($index,'sort','editSortInput')}}">
					<el-input
					  v-if="editable[$index]"
					  v-model="row.sort"
					  size="mini"
					  class="editSortInput"
					></el-input>
					<span v-else>{{row.sort}}</span>
					</div>
				  </template>
				</el-table-column>
				<el-table-column label="上线" min-width="60" align="center">
					<template slot-scope="scope">
						<el-switch
						 v-model="scope.row.status"
						 @change="changeStatus(scope.row,'status')"
						 active-color="#13ce66"
						 :active-value="1"
						 :inactive-value="2">
						 </el-switch>
					</template>
				</el-table-column>
				<el-table-column label="推荐" min-width="60" align="center">
					<template slot-scope="scope">
						<el-switch
						 v-model="scope.row.is_rec"
						 @change="changeStatus(scope.row,'is_rec')"
						 active-color="#13ce66"
						 :active-value="1"
						 :inactive-value="2">
						 </el-switch>
					</template>
				</el-table-column>
				<el-table-column label="热门" min-width="60" align="center">
				  <template slot-scope="scope">
				  	<el-switch
				  	 v-model="scope.row.is_hot"
				  	 @change="changeStatus(scope.row,'is_hot')"
				  	 active-color="#13ce66"
				  	 :active-value="1"
				  	 :inactive-value="2">
				  	 </el-switch>
				  </template>
				</el-table-column>
				<el-table-column label="新品" min-width="60" align="center">
				  <template slot-scope="scope">
				  	<el-switch
				  	 v-model="scope.row.is_new"
				  	 @change="changeStatus(scope.row,'is_new')"
				  	 active-color="#13ce66"
				  	 :active-value="1"
				  	 :inactive-value="2">
				  	 </el-switch>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="180">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center" fixed="right">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'goods',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
			}
		},
		mounted() {
			
		},
		methods:{
			handleAdd(){
				this.$router.push({
				  path:"/goods_edit",
				  query: {}
				})
			},
			handleEdit(row) {
				this.$router.push({
				  path:"/goods_edit",
				  query: {
					  info:JSON.parse(JSON.stringify(row))
				  }
				})
			},
		}
	}
</script>

<style>
</style>